<template>
	<CDropdown
		inNav
		class="c-header-nav-items"
		placement="bottom-end"
		add-menu-classes="pt-0"
	>
		<template #toggler>
			<CHeaderNavLink>
				<div class="c-avatar">
					<img src="img/avatars/6.jpg" class="c-avatar-img" />
				</div>
			</CHeaderNavLink>
		</template>
		<CDropdownHeader tag="div" class="text-center" color="light">
			<strong>Account</strong>
		</CDropdownHeader>
		<CDropdownItem>
			<CIcon name="cil-bell" /> Updates
			<CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
		</CDropdownItem>
		<CDropdownItem>
			<CIcon name="cil-envelope-open" /> Messages
			<CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
		</CDropdownItem>
		<CDropdownItem>
			<CIcon name="cil-task" /> Tasks
			<CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
		</CDropdownItem>
		<CDropdownItem>
			<CIcon name="cil-comment-square" /> Comments
			<CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
		</CDropdownItem>
		<CDropdownHeader tag="div" class="text-center" color="light">
			<strong>Settings</strong>
		</CDropdownHeader>
		<CDropdownItem> <CIcon name="cil-user" /> Profile </CDropdownItem>
		<CDropdownItem> <CIcon name="cil-settings" /> Settings </CDropdownItem>
		<CDropdownItem>
			<CIcon name="cil-dollar" /> Payments
			<CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
		</CDropdownItem>
		<CDropdownItem>
			<CIcon name="cil-file" /> Projects
			<CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
		</CDropdownItem>
		<CDropdownDivider />
		<CDropdownItem>
			<CIcon name="cil-shield-alt" /> Lock Account
		</CDropdownItem>
		<CDropdownItem @click="logout">
			<CIcon name="cil-lock-locked" /> Logout
		</CDropdownItem>
	</CDropdown>
</template>

<script>
export default {
	name: "TheHeaderDropdownAccnt",
	data() {
		return {
			itemsCount: 42,
		};
	},
	methods: {
		async logout() {
			this.$store.commit('loadState', true);
			this.$store.dispatch('logout').then(() => {
				this.$router.push('/login');
				this.$store.commit('loadState', false);
			});
		},
	},
};
</script>

<style scoped>
.c-icon {
	margin-right: 0.3rem;
}
</style>