<template>
	<CSidebar
		fixed
		:minimize="minimize"
		:show="show"
		@update:show="(value) => $store.commit('set', ['sidebarShow', value])"
	>
		<CSidebarBrand
			class="d-md-down-none"
			to="/"
		>
			<CImg
				class="c-sidebar-brand-full"
				:src="logo"
			/>
			<CIcon
				class="c-sidebar-brand-minimized"
				:src="logoSmall"
				block
			/>
		</CSidebarBrand>

		<CRenderFunction
			flat
			:content-to-render="$options.nav"
		/>
		<CSidebarMinimizer
			class="d-md-down-none"
			@click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
		/>
	</CSidebar>
</template>

<script>
import nav from './_nav'

export default {
	name: 'TheSidebar',
	nav,
	data() {
		return {
			logo: require("@/assets/images/logo_white.png"),
			logoSmall: require("@/assets/images/logo-small.png"),
		}
	},
	computed: {
		show() {
			return this.$store.state.sidebarShow
		},
		minimize() {
			return this.$store.state.sidebarMinimize
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.c-sidebar {
	.c-sidebar-brand {
		background: rgb(255 255 255) !important;
	}
}
img {
	&.c-sidebar-brand-full {
		width: 50%;
	}
}

.c-sidebar-brand {
	flex: 0 0 100px;
}

.c-sidebar-minimized {
	.c-sidebar-brand-full {
		display: none;
	}
}
.c-sidebar-brand-minimized {
	max-width: 30px;
}
</style>


