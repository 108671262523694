<template>
	<CHeader
		fixed
		with-subheader
		light
	>
		<CToggler
			in-header
			class="ml-3 d-lg-none"
			@click="$store.commit('toggleSidebarMobile')"
		/>
		<CToggler
			in-header
			class="ml-3 d-md-down-none"
			@click="$store.commit('toggleSidebarDesktop')"
		/>
		<CHeaderBrand
			class="mx-auto d-lg-none"
			to="/"
		>
			<CImg
				class="c-header-brand-full"
				:src="logo"
			/>
		</CHeaderBrand>
		<CHeaderNav class="d-md-down-none mr-auto">
			<CBreadcrumbRouter class="border-0 mb-0" />
		</CHeaderNav>
		<div
			class="logout"
			@click="logout"
		>
			<h5>Esci</h5>
		</div>
	</CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
	name: 'TheHeader',
	components: {
		TheHeaderDropdownAccnt,
	},
	data() {
		return {
			logo: require("@/assets/images/logo.png"),
		}
	},
	methods: {
		logout() {
			this.$store.commit('loadState', true);
			this.$store.dispatch('logout').then(() => {
				this.$router.push('/login');
				this.$store.commit('loadState', false);
			});
		},
	},
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/style";
img {
	&.c-header-brand-full {
		width: 120px;
		display: inline-block;
	}
}
.logout {
	margin-right: 2rem;
	display: flex;
	align-items: center;
	color: $sdRed;
	cursor: pointer;

	h5 {
		margin-bottom: 0;
	}
}
</style>