export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Sezione soggetti']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Utenti',
        to: '/utenti',
        fontIcon: 'fas fa-users'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Sezione esercizi']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Allenamenti',
        to: '/allenamenti',
        fontIcon: 'fas fa-dumbbell'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Consigli nutrizionali']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Consigli Nutrizionali',
        to: '/consigli-nutrizionali',
        fontIcon: 'fas fa-drumstick-bite'
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['Configurazioni']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Configurazioni',
        route: '/configurazione',
        fontIcon: 'fas fa-cogs',
        items: [
          {
            name: 'Notifiche',
            to: '/configurazione/notifiche',
            fontIcon: 'fas fa-bell',
          },
          {
            name: 'Promemoria',
            to: '/configurazione/promemoria',
            fontIcon: 'fas fa-calendar',
          },
        ]
      }
    ]
  }
]